import React, { useState, useEffect, useContext } from "react";
import ToDoTableMaint from "../../updatedcomponents/tables/ToDoTableMaint";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import WorkOrderListPrint from "../../updatedcomponents/modals/WorkOrderListPrint";
import ToDoTable from "../../updatedcomponents/tables/ToDoTable";
import { FacilityContext } from "../../context/FacilityContext";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import { ArrowBack, RefreshOutlined } from "@mui/icons-material";
import WorkOrderDetails from "./WorkOrderDetails";
import TaskNotes from "../../updatedcomponents/modals/TaskNotes";
import TableFormRHF from "../../updatedcomponents/forms/TableFormRHF";
import { useAuth } from "../../hooks/useAuth";
import { WorkOrder } from "../../types/WorkOrder";
import { RecurringTask } from "../../types/RecurringTask";
import { TaskInstance } from "../../types/TaskInstance";

const TasksWorker: React.FC = () => {
  const { selectedFacility } = useContext(FacilityContext);
  const { currentUser } = useAuth();
  const [workOrders, setWorkOrders] = useState<WorkOrder[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showPrintWorkOrders, setShowPrintWorkOrders] =
    useState<boolean>(false);

  const [recurringTask, setRecurringTask] = useState<TaskInstance[]>([]);

  const [selectedTask, setSelectedTask] = useState<TaskInstance | null>(null);
  const [showWorkOrder, setShowWorkOrder] = useState<boolean>(false);
  const [showRecurringTask, setShowRecurringTask] = useState<boolean>(false);
  const [instanceToEdit, setInstanceToEdit] = useState<TaskInstance | null>(
    null
  );

  const employeeId = currentUser._id;

  const [showNotes, setShowNotes] = useState<boolean>(false);
  const [images, setImages] = useState<string[]>([]);

  //Enpoint for fetching work orders
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        setIsLoading(true);

        const response = await axios.get(
          `/api/employee-tasks/list/${employeeId}?facility=${selectedFacility}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setWorkOrders(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching work orders:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [refresh, employeeId]);

  //Enpoint for fetching recurring tasks
  useEffect(() => {
    if (selectedFacility && employeeId) {
      const fetchData = async () => {
        try {
          const token = localStorage.getItem("token");
          setIsLoading(true);

          const response = await axios.get(
            `/api/employee-tasks/recurring/list/${selectedFacility}/${employeeId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setRecurringTask(response.data);
        } catch (error) {
          console.error("Error fetching recurring tasks:", error);
        }
      };

      fetchData();
    }
  }, [refresh, selectedFacility, employeeId]);

  const handleLogInstanceView = (logInstance: TaskInstance) => {
    setInstanceToEdit(logInstance);
    setShowRecurringTask(true);
  };

  const content = (
    <div>
      {!(showWorkOrder || showRecurringTask) ? (
        <div className="relative">
          <PageHeader
            title={"To Do List"}
            subtitle={"Tasks that have not yet been closed out."}
            buttons={
              <button
                className="mx-2 flex-1 flex items-center gap-2 justify-center rounded-sm bg-secondary-100 px-2 py-1 h-fit text-center text-sm text-primary border-transparent border hover:bg-secondary-200"
                onClick={() => setRefresh(!refresh)}
              >
                <RefreshOutlined fontSize="small" /> <span>Refresh</span>
              </button>
            }
          />
          {/* Work Orders */}
          <div className="rounded-sm border border-secondary-1000 p-2 sm:px-4 m-2 min-h-96 overflow-y-auto">
            <h2 className="py-2 text-secondary-100 font-medium">Work Orders</h2>
            <ToDoTableMaint
              data={workOrders}
              setData={setWorkOrders}
              selectedTask={selectedTask}
              setSelectedTask={setSelectedTask}
              setShowNotes={setShowNotes}
            />
          </div>
          <div className="rounded-sm border border-secondary-1000 p-2 m-2 min-h-96 overflow-y-auto">
            <h2 className="py-2 text-secondary-100 font-medium">
              Recurring Tasks
            </h2>
            <ToDoTable
              data={recurringTask}
              users={[]}
              setShowForm={setShowRecurringTask}
              handleLogInstanceView={handleLogInstanceView}
              setInstanceToEdit={setInstanceToEdit}
            />
          </div>

          {/*   <div className="flex w-full justify-center align-middle">
          <button
            className=" mt-8 bg-gray-50 text-secondary p-4 rounded-lg border border-secondary border-dashed "
            onClick={handlePrintList}
          >
            Print Tasks
          </button>
        </div> */}
          {showPrintWorkOrders && (
            <div>
              <WorkOrderListPrint
                showPrintWorkOrders={showPrintWorkOrders}
                setShowWorkOrderPrint={setShowPrintWorkOrders}
                users={[currentUser]}
              />
            </div>
          )}
        </div>
      ) : (
        <div>
          {showWorkOrder && (
            <div>
              <PageHeader
                title={
                  <button
                    type="button"
                    className="flex gap-2 items-center text-md font-normal text-secondary-300 hover:text-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100 "
                    onClick={() => {
                      setShowWorkOrder(false);
                      setShowRecurringTask(false);
                    }}
                  >
                    <ArrowBack style={{ fontSize: "1rem" }} /> Back to list
                  </button>
                }
              />
              <WorkOrderDetails
                permissions={currentUser.access}
                workOrder={selectedTask}
                setSelectedNotes={setShowNotes}
                setWorkOrder={setSelectedTask}
                images={images}
                users={[]}
              />
            </div>
          )}
          {showRecurringTask && (
            <div>
              <PageHeader
                title={
                  <button
                    type="button"
                    className="flex gap-2 items-center text-md font-normal text-secondary-300 hover:text-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100 "
                    onClick={() => {
                      setShowWorkOrder(false);
                      setShowRecurringTask(false);
                      setInstanceToEdit(null);
                      setImages([]);
                    }}
                  >
                    <ArrowBack style={{ fontSize: "1rem" }} /> Back to list
                  </button>
                }
              />
              <TableFormRHF
                logData={instanceToEdit}
                setLogData={setInstanceToEdit}
                setShowForm={setShowRecurringTask}
                refresh={refresh}
                setRefresh={setRefresh}
                setCloseoutButton={null}
                response={null}
                setResponse={null}
              />
            </div>
          )}
        </div>
      )}
      {showNotes && selectedTask && (
        <TaskNotes
          selectedTask={selectedTask}
          showNotes={showNotes}
          setShowNotes={setShowNotes}
        />
      )}
    </div>
  );
  return <div>{content}</div>;
};

export default TasksWorker;
