import { useNavigate } from "react-router-dom";
import { CalendarMonthOutlined, Person } from "@mui/icons-material";
import StatusBadge from "../general-ui/StatusBadges";
import { calculateDays, capitalizeString } from "../../utils/FormatFunctions";
import Container from "../general/Container";
import Table from "../general/Table";
import { WorkOrder } from "../../types/WorkOrder";

interface UrgentWorkOrderTableProps {
  workOrders: WorkOrder[];
}

const UrgentWorkOrderTable = ({ workOrders }: UrgentWorkOrderTableProps) => {
  const navigate = useNavigate();

  const handleView = (workOrderId: string) => {
    navigate(
      `/work-orders/instance/${workOrderId}?filters=%7B"urgency"%3A"urgent"%7D`
    );
  };

  const columns = [
    {
      label: "Request Title",
      key: "title",
      render: (workOrder: WorkOrder) => (
        <>
          <div className=" text-sm font-medium  text-secondary-100 ">
            <span className="sm:max-w-96 max-w-56 flex overflow-auto text-sm">
              {capitalizeString(workOrder.title)}
            </span>
            <dl className="">
              <dt className="sr-only hidden">Details</dt>
              <dd className="mt-1 sm:max-w-72 max-w-56  text-secondary-100  text-xs font-light">
                {workOrder.details}
              </dd>
              <dt className="sr-only sm:hidden">Days since reported</dt>
              <dd className="mt-1 truncate text-secondary-100 text-xs sm:hidden font-light">
                <CalendarMonthOutlined style={{ fontSize: "1rem" }} />{" "}
                {calculateDays(workOrder.dateReported)} days ago
              </dd>
              <dd className="sm:hidden mt-1 flex w-full justify-between text-secondary-100 font-normal text-xs">
                <StatusBadge status={workOrder.status} />

                <span className="">
                  {workOrder.assignedTo ? (
                    <span>
                      <Person
                        style={{ fontSize: "1rem" }}
                        className="h-4 w-4 mr-1"
                      />
                      {capitalizeString(
                        workOrder.assignedTo.firstname.slice(0, 1)
                      )}{" "}
                      {capitalizeString(workOrder.assignedTo.lastname)}
                    </span>
                  ) : (
                    <span>
                      <Person
                        style={{ fontSize: "1rem" }}
                        className="h-4 w-4 mr-1"
                      />
                      Unassigned
                    </span>
                  )}
                </span>
              </dd>

              <dt className="sr-only hidden">Days since reported</dt>
              <dd className="mt-1 truncate text-secondary-100 hidden font-light">
                {calculateDays(workOrder.dateReported)} days
              </dd>

              <dt className="sr-only hidden">Status and urgency</dt>
              <dd className="mt-1 truncate text-secondary-100 sm:hidden flex w-full justify-between my-2">
                <StatusBadge status={workOrder.urgency} />
                <span className="text-secondary-100 font-normal text-xs">
                  ID {workOrder?.shortId}
                </span>
              </dd>
            </dl>
          </div>{" "}
        </>
      ),
      className: "w-full max-w-0 py-4 pr-3 sm:w-auto sm:max-w-none ",
    },
    {
      label: "Date Reported",
      key: "dateReported",
      render: (workOrder: WorkOrder) => (
        <>
          <span className=" text-secondary">
            {calculateDays(workOrder.dateReported)} days ago
          </span>
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Status",
      key: "status",
      render: (workOrder: WorkOrder) => (
        <>
          <StatusBadge status={workOrder.status} />
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Urgency",
      key: "urgency",
      render: (workOrder: WorkOrder) => (
        <>
          <StatusBadge status={workOrder.urgency} />
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Assigned to",
      key: "assignedTo",
      render: (workOrder: WorkOrder) => (
        <>
          <span className=" text-secondary">
            {workOrder.assignedTo ? (
              <span>
                <Person style={{ fontSize: "1rem" }} className="h-4 w-4 mr-1" />
                {capitalizeString(
                  workOrder.assignedTo.firstname.slice(0, 1)
                )}{" "}
                {capitalizeString(workOrder.assignedTo.lastname)}
              </span>
            ) : (
              <span>
                <Person style={{ fontSize: "1rem" }} className="h-4 w-4 mr-1" />
                Unassigned
              </span>
            )}
          </span>
        </>
      ),
      className: "hidden sm:table-cell",
    },
  ];

  return (
    <Container>
      <h1 className="text-base font-semibold mb-2 text-secondary-100">
        Urgent Work Orders
      </h1>

      <Table
        data={workOrders}
        onRowClick={(workOrder) => handleView(workOrder._id)}
        columns={columns}
      />
    </Container>
  );
};

export default UrgentWorkOrderTable;
