import React from "react";
import axios from "axios";
import {
  BusinessOutlined,
  CalendarMonthOutlined,
  DateRangeOutlined,
  EmailOutlined,
  RepeatOutlined,
} from "@mui/icons-material";
import moment from "moment";
import {
  capitalizeString,
  formatDate,
  classNames,
} from "../../utils/FormatFunctions";
import Table from "../general/Table";
import { TaskInstance } from "../../types/TaskInstance";
import { useFacility } from "../../hooks/useFacility";
import { User } from "../../types/User";
import StatusBadge from "../general-ui/StatusBadges";
import AssignmentSelector from "../general-ui/AssignmentSelector";
import { WorkOrder } from "../../types/WorkOrder";

interface ToDoTableProps {
  data: TaskInstance[];
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  users: any[];
  setInstanceToEdit: React.Dispatch<React.SetStateAction<TaskInstance | null>>;
  handleLogInstanceView: (task: TaskInstance) => void;
}

const ToDoTable: React.FC<ToDoTableProps> = ({
  data,
  users,
  handleLogInstanceView,
}) => {

  const { selectedFacility } = useFacility();

  const handleAssignment = (task: TaskInstance , selectedUser: string | null) => {
    if (!task.childId) {
      task.childId = "createId";
    }
    
    axios
      .put(
        `/api/log/assign`,
        {
          assignedTo:
            typeof selectedUser === "string" && selectedUser === "unassign"
              ? null
              : selectedUser,
          taskInfo: task,
          facility: selectedFacility,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log("Task assigned successfully:", response.data);
      })
      .catch((error) => {
        console.error("Error assigning task:", error);
      });
  };

  const columns = [
    {
      label: "Task",
      key: "title",
      render: (taskInstance: TaskInstance) => (
        <>
          <span className="max-w-24 truncate text-secondary">
            {capitalizeString(taskInstance.title)}
          </span>
          <div className="whitespace-nowrap text-sm">
            <div className="flex flex-col">
              <div className="mt-1 text-secondary-300 gap-2 flex items-center">
                {taskInstance.recurrence && (
                  <span
                    className={classNames(
                      "flex items-center gap-1 text-xs",
                      taskInstance.status === "overdue"
                        ? "text-reds-600"
                        : "text-secondary-400"
                    )}
                  >
                    <DateRangeOutlined style={{ fontSize: "1rem" }} />
                    {formatDate(taskInstance.date, "MMM DD, YYYY", "utc")}
                  </span>
                )}
              </div>
              <div className="mt-1 text-secondary-400 gap-2 flex items-center">
                {taskInstance.recurrence && (
                  <span className="flex items-center gap-1 text-xs">
                    <RepeatOutlined style={{ fontSize: "1rem" }} />
                    {capitalizeString(taskInstance.recurrence.frequency)}
                  </span>
                )}
              </div>
            </div>
          </div>
        </>
      ),
      className: "w-full max-w-0 py-4 pr-3 sm:w-auto sm:max-w-none ",
    },

    {
      label: "Status",
      key: "status",
      render: (taskInstance: TaskInstance) => (
        <span>{<StatusBadge status={taskInstance.status} />}</span>
      ),
      className: "table-cell",
    },

    {
      label: "Source",
      key: "source",
      render: (taskInstance: TaskInstance) => (
        <span>{capitalizeString(taskInstance.source)}</span>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Details",
      key: "details",
      render: (taskInstance: TaskInstance) => (
        <span className="max-w-40 truncate">
          {capitalizeString(taskInstance.details)}
        </span>
      ),
      className: "hidden sm:table-cell",
    },

    {
      label: "Assign",
      key: "assignedTo",
      render: (taskInstance: TaskInstance) => (
        <div onClick={(e) => e.stopPropagation()}>
          <AssignmentSelector
            users={users}
            selectedUser={
              taskInstance.assignedTo ? taskInstance.assignedTo : null
            }
            task={taskInstance}
            handleChange={handleAssignment}
          />
        </div>
      ),
      className: "hidden sm:table-cell",
    },
  ];
  return (
    <Table columns={columns} data={data} onRowClick={handleLogInstanceView} />
  );
};

export default ToDoTable;
