import {
  BuildOutlined,
  CalendarMonthOutlined,
  ChevronLeft,
  ChevronRight,
  PlumbingOutlined,
} from "@mui/icons-material";
import  { useState } from "react";

const LandingPage = () => {
  const [currentSection, setCurrentSection] = useState(1);

  const features = [
    {
      imgSrc: "/landing/tools.png",
      title: "The tools you need",
      description: `Sequra possesses all of the tools you need to ensure your facility is compliant, safe, and efficiently run. With intuitive reporting, expretly curated task templates, and advanced notification systems, Sequra's tools are a must for any comprehensive maintenance program.`,
      buttonText: "Learn More",
    },
    {
      imgSrc: "/landing/analytics.png",
      title: "In Depth Analytics",
      description:
        "In depth analytics provide meaningful insights regarding your facility's standing as well as help monitor employees performance. Sequra's analytics are designed to help you make informed decisions regarding your facility's maintenance and budgeting.",
      buttonText: "Learn More",
    },
    {
      imgSrc: "/landing/shield.png",
      title: "Expertly Constructed",
      description:
        "Built by industry experts, Sequra is designed to meet the real needs of your facility to ensure compliance with CMS and NFPA regulations. Sequra has done the research so you don't have to.",
      buttonText: "Learn More",
    },
  ];

  return (
    <div className="relative">
      <header className="sticky top-0">
        <div className=" bg-secondary-1100 flex w-full justify-between px-10 py-5 border border-b border-secondary-1000">
          <img src="/sequra_logo__primary_black.png" className="h-9 w-auto" />
          <div>
            <nav>
              <ul className="flex items-center gap-9">
                <li className="">Features</li>
                <li className="">Testimonials</li>
                <li className="">Contact</li>
                <li>
                  <button className="bg-accent-500 rounded px-4 py-2 text-primary text-md leading-6 hover:bg-accent-600">
                    Login
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>

      {/* Hero Section */}
      <section className="flex sm:flex-row flex-col justify-between px-10 py-16">
        <div className="sm:w-2/5 w-full flex flex-col gap-10">
          <h1 className="font-header text-7xl leading-[90px] text-secondary-100 font-medium">
            Facility Management, Modernized
          </h1>
          <span>
            Sequra is a cloud-based facility management software that helps
            facilities maintain the highest standards of compliance, safety, and
            efficiency. Our software is designed to streamline preventative
            maintenance, life safety, and work order management for any
            facility. Easily find reports, manage your facilities needs, and
            keep your team on track with Sequra.
          </span>
          <button className="bg-accent-500 rounded px-4 py-2 text-primary text-md leading-6 hover:bg-accent-600 flex w-fit">
            Request A Demo
          </button>
        </div>
        <div className="w-1/2 items-center flex">
          <img src="/landing/devices.png" className="w-full h-fit" />
        </div>
      </section>

      {/* Features Section */}
      <section className="bg-secondary-1100 px-10 py-16 flex flex-col gap-8">
        <h2 className="text-center font-header text-5xl font-medium leading-10">
          Command Your Facility's Maintenance
        </h2>
        <div className="flex w-full gap-8">
          {features.map((section, index) => (
            <div
              key={index}
              className="flex flex-col flex-1 items-center justify-center border-2 border-accent-800 rounded p-6 gap-5"
            >
              <img src={section.imgSrc} className="w-36 h-36 rounded-full " />
              <div className="gap-3">
                <h3 className="text-center text-secondary-100 font-semibold leading-6 text-lg">
                  {section.title}
                </h3>
                <p className="text-justify leading-6">{section.description}</p>
              </div>
              <button className="border-secondary-100 border-2 rounded px-4 py-2 font-medium">
                {section.buttonText}
              </button>
            </div>
          ))}
        </div>
      </section>

      {/* Feature example section */}
      <section className="bg-primary px-10 py-16 flex flex-col gap-8">
        <div className="max-w-[900px] gap-4 flex flex-col">
          <h1 className="font-header font-medium text-5xl">
            A Management Powerwhouse
          </h1>
          <p className="text-lg leading-6">
            Simple, flexible, and powerful. Easily track tasks, staff
            assignments, and maintenance activities to get a clear view of who’s
            doing what and what needs to be done.
          </p>
        </div>
        <div className="flex w-full justify-between">
          <ul className="flex flex-col gap-5 w-1/3">
            <li
              className={`cursor-pointer rounded border-2 p-4 gap-2 flex flex-col ${
                currentSection === 1
                  ? "border-accent-500 bg-secondary-1100"
                  : "border-transparent bg-primary"
              }`}
              onClick={() => setCurrentSection(1)}
            >
              <span className="flex items-center gap-2 font-semibold leading-6">
                <BuildOutlined style={{ fontSize: "1.2rem" }} />
                Work Orders
              </span>
              <p>
                Efficiently prioritize and track unplanned maintenance tasks.
                From minor issues like a leaky faucet to more significant
                repairs, users can easily enter and manage ad hoc requests
                within a single system. Monitor costs, assign tasks, and track
                completion seamlessly.
              </p>
            </li>
            <li
              className={`cursor-pointer rounded border-2 p-4 gap-2 flex flex-col ${
                currentSection === 2
                  ? "border-accent-500 bg-secondary-1100"
                  : "border-transparent bg-primary"
              }`}
              onClick={() => setCurrentSection(2)}
            >
              <span className="flex items-center gap-2 font-semibold leading-6">
                <CalendarMonthOutlined style={{ fontSize: "1.2rem" }} />
                Task Management
              </span>
              <p>
                Efficiently prioritize and track unplanned maintenance tasks.
                From minor issues like a leaky faucet to more significant
                repairs, users can easily enter and manage ad hoc requests
                within a single system. Monitor costs, assign tasks, and track
                completion seamlessly.
              </p>
            </li>
            <li
              className={`cursor-pointer rounded border-2 p-4 gap-2 flex flex-col ${
                currentSection === 3
                  ? "border-accent-500 bg-secondary-1100"
                  : "border-transparent bg-primary"
              }`}
              onClick={() => setCurrentSection(3)}
            >
              <span className="flex items-center gap-2 font-semibold leading-6">
                <PlumbingOutlined style={{ fontSize: "1.2rem" }} />
                Preventative Maintenance
              </span>
              <p>
                Efficiently prioritize and track unplanned maintenance tasks.
                From minor issues like a leaky faucet to more significant
                repairs, users can easily enter and manage ad hoc requests
                within a single system. Monitor costs, assign tasks, and track
                completion seamlessly.
              </p>
            </li>
          </ul>
          <div className="flex-1 flex justify-center items-center">
            {" "}
            {currentSection === 1 && (
              <div className="bg-secondary-1100 p-6 rounded border-2 border-secondary-1000">
                <img src={"/landing/work-orders.png"} alt="Work Order" />
              </div>
            )}
            {currentSection === 2 && (
              <div className="bg-secondary-1100 p-6 rounded border-2 border-secondary-1000">
                <img src={"/landing/work-orders.png"} alt="Work Order" />
              </div>
            )}
            {currentSection === 3 && (
              <div className="bg-secondary-1100 p-6 rounded border-2 border-secondary-1000">
                <img src={"/landing/work-orders.png"} alt="Work Order" />
              </div>
            )}
          </div>
        </div>
      </section>
      {/* Testimonials Section */}
      <section className="py-16 bg-secondary-1100 gap-8 flex flex-col">
        <h2 className="text-center font-header text-5xl font-medium leading-10">
          Hear From Our Happy Customers
        </h2>
        <div className="w-full flex gap-6 items-center">
          <ChevronLeft
            className="text-accent-500"
            style={{ fontSize: "3rem" }}
          />
          <div className="bg-primary h-72 flex-1 border-2 border-secondary-1000"></div>
          <div className="bg-primary h-72 flex-1 border-2 border-secondary-1000"></div>
          <div className="bg-primary h-72 flex-1 border-2 border-secondary-1000"></div>
          <div className="bg-primary h-72 flex-1 border-2 border-secondary-1000"></div>
          <ChevronRight
            className="text-accent-500"
            style={{ fontSize: "3rem" }}
          />
        </div>
      </section>
      {/* Contact Section */}
      <section>{/* Contact content */}</section>

      {/* Footer */}
      <footer>{/* Footer content */}</footer>
    </div>
  );
};

export default LandingPage;
