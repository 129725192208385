import React from "react";
import * as MuiIcons from "@mui/icons-material";
import { classNames } from "../../utils/FormatFunctions";

export type ButtonTypes = "primary" | "secondary" | "danger" | "bold";

type MuiIconNames = keyof typeof MuiIcons;

type ButtonProps = {
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  onClick?: (e: any) => void;
  style?: ButtonTypes;
  children: React.ReactNode;
  icon?: MuiIconNames;
  className?: string;
};

const Button: React.FC<ButtonProps> = ({
  type = "button",
  disabled,
  onClick,
  style = "primary",
  children,
  icon,
  className = "",
}) => {
  let buttonStyle;
  switch (style) {
    case "primary":
      buttonStyle =
        "bg-secondary text-primary border border-transparent shadow-sm hover:bg-secondary-200 ";
      break;
    case "bold":
      buttonStyle =
        "bg-accent text-text border border-transparent shadow-sm hover:bg-accent-600 ";
      break;
    case "secondary":
      buttonStyle =
        "bg-transparent text-secondary border border-secondary shadow-sm";
      break;
    case "danger":
      buttonStyle = "bg-reds text-primary shadow-sm hover:bg-reds-600";
      break;
    default:
      buttonStyle =
        "bg-secondary text-text-100 border border-transparent shadow-sm hover:bg-secondary-200 ";
      break;
  }
  const IconComponent = icon ? MuiIcons[icon as MuiIconNames] : null;

  return (
    <div
      className={classNames(
        buttonStyle,
        "bg-reds-800 flex justify-center items-center w-fit h-fit rounded-sm px-3.5 py-2 text-sm text-nowrap",
        disabled
          ? "cursor-not-allowed bg-secondary-1000 hover:bg-grayscale-300 text-grayscale-600 hover:cursor-disabled"
          : "hover:cursor-pointer",
        className
      )}
      onClick={onClick}
    >
      {IconComponent && (
        <IconComponent className="mr-1" style={{ fontSize: "1rem" }} />
      )}
      <button
        className="flex w-full justify-center"
        type={type}
        disabled={disabled}
      >
        {children}
      </button>
    </div>
  );
};

export default Button;
