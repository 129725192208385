import { useState, Fragment } from "react";
import { Popover } from "@headlessui/react";
import { CalendarDaysIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import moment from "moment";
import {
  ChevronLeft,
  ChevronRight,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import Button from "../general/Button";

interface DateParams {
  displayText: string;
  startDate: Date | null;
  endDate: Date | null;
  selectedRange: string;
}

interface DateFilterProps {
  dateParams: DateParams;
  setDateParams: React.Dispatch<React.SetStateAction<DateParams>>;
}

interface Day {
  date: string;
  isCurrentMonth: boolean;
  isToday: boolean;
  isSelected: boolean;
}

const DateFilter: React.FC<DateFilterProps> = ({
  dateParams,
  setDateParams,
}) => {
  const [customView, setCustomView] = useState<boolean>(false);

  const [month, setMonth] = useState<number>(moment().month());
  const days: Day[] = Array.from({ length: 42 }, (_, i) => {
    const date = moment().month(month).startOf("month").add(i, "days");

    return {
      date: date.format("YYYY-MM-DD"),
      isCurrentMonth: date.month() === month,
      isToday: date.isSame(moment(), "day"),
      isSelected:
        date.isSame(dateParams.startDate, "day") ||
        date.isSame(dateParams.endDate, "day"),
    };
  });

  function classNames(...classes: (string | boolean | undefined)[]): string {
    return classes.filter(Boolean).join(" ");
  }

  const calendar = () => {
    const handleDateSelect = (date: string) => {
      if (!dateParams.startDate) {
        setDateParams({
          ...dateParams,
          startDate: new Date(date),
          displayText: `${moment(date).format("MMM. DD")} - ${moment(
            dateParams.endDate
          ).format("MMM. DD")}`,
        });
      } else if (
        !dateParams.endDate ||
        moment(date).isAfter(dateParams.endDate)
      ) {
        setDateParams({
          ...dateParams,
          endDate: new Date(date),
          displayText: `${moment(dateParams.startDate).format(
            "MMM. DD"
          )} - ${moment(date).format("MMM. DD")}`,
          selectedRange: "custom",
        });
      } else {
        setDateParams({
          ...dateParams,
          startDate: new Date(date),
          endDate: null,
          displayText: `${moment(date).format("MMM. DD")} - ${moment(
            dateParams.endDate
          ).format("MMM. DD")}`,
        });
      }
    };

    return (
      <div className="flex flex-col w-fit wrap-none">
        <div className="flex items-center text-secondary-100">
          <button
            type="button"
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-secondary-200 hover:text-secondary-300"
            onClick={() => setMonth(month - 1)}
          >
            <span className="sr-only">Previous month</span>
            <ChevronLeft className="h-5 w-5" aria-hidden="true" />
          </button>
          <div className="flex-auto text-center text-sm font-semibold">
            {moment().month(month).format("MMMM")}
          </div>
          <button
            type="button"
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-secondary-200 hover:text-secondary-300"
            onClick={() => setMonth(month + 1)}
          >
            <span className="sr-only">Next month</span>
            <ChevronRight className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-6 grid grid-cols-7 text-center text-xs leading-6 text-secondary-100">
          <div>M</div>
          <div>T</div>
          <div>W</div>
          <div>T</div>
          <div>F</div>
          <div>S</div>
          <div>S</div>
        </div>
        <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-secondary-1000 text-sm shadow ring-1 ring-secondary-1100">
          {days.map((day, dayIdx) => (
            <button
              key={day.date}
              type="button"
              className={classNames(
                "py-1.5 hover:bg-secondary-1100 focus:z-10",
                day.isCurrentMonth ? "bg-primary" : "bg-primary",
                (day.isSelected || day.isToday) && "font-semibold",
                day.isSelected && "text-primary",
                !day.isSelected &&
                  day.isCurrentMonth &&
                  !day.isToday &&
                  "text-secondary-100",
                !day.isSelected &&
                  !day.isCurrentMonth &&
                  !day.isToday &&
                  "text-secondary-700",
                day.isToday && !day.isSelected && "text-accent-500",
                dayIdx === 0 && "rounded-tl-lg",
                dayIdx === 6 && "rounded-tr-lg",
                dayIdx === days.length - 7 && "rounded-bl-lg",
                dayIdx === days.length - 1 && "rounded-br-lg"
              )}
              onClick={() => handleDateSelect(day.date)}
            >
              <time
                dateTime={day.date}
                className={classNames(
                  "mx-auto flex h-7 w-7 items-center justify-center rounded-full",
                  day.isSelected && day.isToday && "bg-accent-500",
                  day.isSelected && !day.isToday && "bg-secondary-100"
                )}
              >
                {day.date.split("-").pop()?.replace(/^0/, "")}
              </time>
            </button>
          ))}
        </div>
      </div>
    );
  };

  const handleDateRangeChange = (value: string) => {
    if (value === "custom") {
      setCustomView(true);
    } else {
      setCustomView(false);
    }

    let newStartDate: Date;
    let newDisplayText: string;
    const newEndDate = moment().toDate();
    switch (value) {
      case "day":
        newStartDate = moment().subtract(1, "day").toDate();
        newDisplayText = "Today";
        break;
      case "2day":
        newStartDate = moment().subtract(2, "days").toDate();
        newDisplayText = "Yesterday";
        break;
      case "3day":
        newStartDate = moment().subtract(3, "days").toDate();
        newDisplayText = "3 Days";
        break;
      case "week":
        newStartDate = moment().subtract(1, "week").toDate();
        newDisplayText = "1 Week";
        break;
      case "month":
        newStartDate = moment().subtract(1, "month").toDate();
        newDisplayText = moment().format("MMMM YYYY");
        break;
      case "3month":
        newStartDate = moment().subtract(3, "months").toDate();
        newDisplayText = "3 Months";
        break;
      default:
        newStartDate = moment().toDate();
        newDisplayText = "Custom";
    }

    setDateParams({
      ...dateParams,
      startDate: newStartDate,
      endDate: newEndDate,
      displayText: newDisplayText,
      selectedRange: value,
    });
  };

  const dateRanges = [
    { range: "day", label: "Day" },
    { range: "2day", label: "Yesterday" },
    { range: "3day", label: "3 Days" },
    { range: "week", label: "Week" },
    { range: "month", label: "1 Month" },
    { range: "3month", label: "3 Months" },
    { range: "custom", label: "Custom", colSpan: 2 },
  ];

  return (
    <Popover className={"relative"}>
      <Popover.Button as="div" className="sm:w-52 flex justify-end">
        <Button
          style="secondary"
          icon="Today"
          children={
            <div className="justify-between flex sm:w-40">
              {dateParams.displayText}
              <ChevronDownIcon
                className="h-5 w-5 ui-open:transition ui-open:rotate-180"
                aria-hidden="true"
              />
            </div>
          }
        />
      </Popover.Button>
      <Popover.Panel className="absolute z-30 top-14 bg-primary shadow w-fit p-4 gap-y-2">
        {!customView ? (
          <div className="grid grid-cols-2 gap-2">
            {dateRanges.map(({ range, label, colSpan }) => (
              <button
                key={range}
                className={`outline-1 outline rounded-sm p-2 text-sm ${
                  colSpan ? "col-span-2" : ""
                } ${
                  dateParams.selectedRange === range
                    ? "bg-secondary-1000"
                    : "bg-primary"
                }`}
                onClick={() =>
                  range === "custom"
                    ? setCustomView(true)
                    : handleDateRangeChange(range)
                }
              >
                {label}
              </button>
            ))}
          </div>
        ) : (
          <div className="flex flex-col gap-y-2 w-full">
            <button
              className={
                "w-full outline-1 outline rounded-sm px-4 py-2 text-sm "
              }
              onClick={() => setCustomView(false)}
            >
              Select Date Filter
            </button>
            <div className="flex w-full flex-col items-center space-x-2">
              <input
                type="date"
                className="w-full border border-secondary-1000 p-2 rounded-sm focus:ring-1 focus:ring-secondary-700 focus:border-transparent"
                value={moment(dateParams.startDate).format("YYYY-MM-DD")}
                onChange={(e) => {
                  setDateParams({
                    ...dateParams,
                    startDate: new Date(e.target.value),
                    selectedRange: "custom",
                  });
                }}
              />
              <span className="font-light">to</span>
              <input
                type="date"
                className="w-full  border border-secondary-1000 p-2 rounded-sm focus:ring-1 focus:ring-secondary-700 focus:border-transparent"
                value={moment(dateParams.endDate).format("YYYY-MM-DD")}
                onChange={(e) => {
                  setDateParams({
                    ...dateParams,
                    endDate: new Date(e.target.value),
                    selectedRange: "custom",
                  });
                }}
              />
            </div>
            {calendar()}
          </div>
        )}
      </Popover.Panel>
    </Popover>
  );
};

export default DateFilter;
