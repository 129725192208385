import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import StatusBadge from "../../updatedcomponents/general-ui/StatusBadges";
import {
  capitalizeString,
  extractFilenameFromUrl,
  formatDate,
  decodedString,
} from "../../utils/FormatFunctions";
import {
  ArrowBack,
  AttachmentOutlined,
  CheckCircleOutline,
  Close,
  OpenInNew,
} from "@mui/icons-material";
import axios from "axios";

interface Attachment {
  _id: string;
  url: string;
  name: string;
}

interface ParentTask {
  source: string;
  details: string;
  attachments: Attachment[];
}

interface CustomInput {
  dataName: string;
  dataType: string;
  value: any;
  [key: string]: any; // Add this line
}

interface LogInstance {
  date: string;
  status: string;
  parentTask: ParentTask;
  customInput: CustomInput[];
}

interface LocationState {
  logInstance: LogInstance;
}

const LogInstance: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const logInstance = (location.state as LocationState).logInstance;

  const [showSpecs, setShowSpecs] = useState(false);

  const tableKeys =
    logInstance.customInput.length > 0
      ? Object.keys(logInstance.customInput[0]).map(
          (key) => logInstance.customInput[0][key].dataName
        )
      : [];

  const handleViewFile = async (link: string) => {
    try {
      const encodedLink = encodeURIComponent(link);
      await axios
        .get("/api/log/file", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: { encodedLink },
        })
        .then((response) => {
          window.open(response.data.url, "_blank");
        })
        .catch((error) => {
          console.error("Error fetching file:", error);
        });
    } catch (error) {
      console.error("Error fetching file:", error);
    }
  };

  const renderCellContent = (row: CustomInput, key: string) => {
    const prop = row[key];
    if (!prop) return "N/A";

    const { dataType, value } = prop;

    if (dataType === "date") {
      return formatDate(value, "MM/DD/YYYY hh:mm a");
    }
    if (dataType === "text") {
      return decodedString(value);
    }
    if (dataType === "radio") {
      if (value === "Pass") {
        return (
          <div className="text-accent-300 flex items-center gap-1">
            <CheckCircleOutline fontSize="small" /> <span>Pass</span>
          </div>
        );
      } else {
        return (
          <div className="text-reds-300 flex items-center gap-1">
            <Close fontSize="small" /> <span>Fail</span>
          </div>
        );
      }
    }
    if (dataType === "file") {
      return (
        <div className="flex w-full justify-center my-1">
          {value.map((item: string, index: number) => (
            <button
              key={index}
              className="px-2 py-0.5 bg-secondary-1100 rounded-sm font-semibold text-sm cursor-pointer"
              onClick={() => handleViewFile(item)}
            >
              {extractFilenameFromUrl(item)} <OpenInNew fontSize="small" />
            </button>
          ))}
        </div>
      );
    }

    return value;
  };

  const specifications = (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-y-2 text-sm mb-4">
      <label className="text-secondary-100">Due Date:</label>
      <span className="text-gray-800 sm:col-span-2 md:col-span-3">
        {formatDate(logInstance?.date)}
      </span>

      <label className="text-secondary-100">Status:</label>
      <span className="text-gray-800 sm:col-span-2 md:col-span-3">
        <StatusBadge status={logInstance?.status} />
      </span>

      <label className="text-secondary-100">Source:</label>
      <span className="text-gray-800 sm:col-span-2 md:col-span-3">
        {capitalizeString(logInstance?.parentTask?.source)}
      </span>

      <label className="text-secondary-100">Details:</label>
      <span className="text-gray-800 sm:col-span-2 md:col-span-3 border border-secondary-1000 mb-4 px-2 rounded-md">
        {logInstance?.parentTask?.details}
      </span>
      <label className="text-secondary-100">Attachments:</label>
      <span className="text-gray-800 sm:col-span-2 md:col-span-3">
        {logInstance?.parentTask?.attachments?.length > 0 ? (
          logInstance?.parentTask.attachments.map((attachment) => (
            <div key={attachment._id} className="flex gap-2">
              <button
                className="px-2 py-0.5 bg-secondary-1100 rounded-sm font-semibold text-sm cursor-pointer my-1"
                onClick={() => handleViewFile(attachment.url)}
              >
                <AttachmentOutlined fontSize="small" /> {attachment.name}
              </button>
            </div>
          ))
        ) : (
          <p className="text-sm italic">No attachments for this task</p>
        )}
      </span>
    </div>
  );


  const tableContent = (
    <div className="max-h-screen overflow-auto thin-scrollbar">
      <div className="flex mb-12  relative">
        <table className="table-auto w-full border-collapse border border-secondary-1000 ">
          <thead>
            <tr className="sticky top-0 ">
              {tableKeys.map((key, index) => (
                <th
                  className={`p-2 border border-secondary-1000 bg-secondary-1100 text-secondary-100 truncate text-left font-medium text-sm max-w-24 ${
                    index === 0 ? "sticky left-0 " : ""
                  }`}
                  key={key}
                >
                  {capitalizeString(decodedString(key))}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {logInstance.customInput.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {tableKeys.map((key, cellIndex) => (
                  <td
                    className={`border border-secondary-1000 text-left pl-2 text-sm w-fit ${
                      cellIndex === 0 ? "sticky left-0 bg-white" : ""
                    }`}
                    key={key}
                  >
                    {renderCellContent(
                      row,
                      Object.keys(row).find((k) => row[k].dataName === key)!
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <div>
      <PageHeader
        title={
          <button
            type="button"
            className="flex gap-2 items-center text-md font-normal text-secondary-300 hover:text-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100 "
            onClick={() => navigate("/logs")}
          >
            <ArrowBack style={{ fontSize: "1rem" }} /> Return to Log list
          </button>
        }
      />
      <div className="mx-4 lg:mx-6">
        <div className="flex justify-between mt-4 mb-2 text-secondary-100">
          <span className=" font-medium">Details</span>
          <button
            className="px-12 py-2 font-medium text-sm bg-secondary-1100 rounded-sm"
            onClick={() => setShowSpecs(!showSpecs)}
          >
            {showSpecs ? "Hide" : "Show"} Details
          </button>
        </div>
        {showSpecs && specifications}
        {tableContent}
      </div>
    </div>
  );
};

export default LogInstance;
