import React, { useState, useEffect, useContext } from "react";
import WorkOrdersTable from "../../updatedcomponents/tables/WorkOrdersTable";
import NewWorkOrder from "../../updatedcomponents/forms/WorkOrderForm";
import WorkOrderListPrint from "../../updatedcomponents/modals/WorkOrderListPrint";
import { useNavigate } from "react-router-dom";
import Pagination from "../../updatedcomponents/table-components/Pagination";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import useFetchData from "../../hooks/useFetchData";
import FilterModal from "../../updatedcomponents/table-components/FilterModal";
import Button from "../../updatedcomponents/general/Button";
import FilterButton from "../../updatedcomponents/table-components/FilterButton";
import { useWorkOrder } from "../../hooks/useWorkOrder";
import { Kiosk } from "../../types/Kiosk";
import { User } from "../../types/User";
import { useFacility } from "../../hooks/useFacility";

const WorkOrders = () => {
  const {
    workOrders,
    setWorkOrders,
    totalPages,
    isLoading,
    searchParams,
    updateSearchParams,
    fetchWorkOrders,
    page,
    pageSize,
    handlePageSizeChange,
    handlePageChange,
    handleView,
    filters,
    applyFilters,
    resetFilters,
  } = useWorkOrder();

  const { selectedFacility } = useFacility();

  const [refresh, setRefresh] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showPrintWorkOrders, setShowPrintWorkOrders] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [activeFilterCount, setActiveFilterCount] = useState(0);

  useEffect(() => {
    if (!selectedFacility) return;
    fetchWorkOrders(selectedFacility);
    updateActiveFilterCount();
  }, [searchParams, selectedFacility]);

  const updateActiveFilterCount = () => {
    const currentFilters = JSON.parse(searchParams.get("filters") || "{}");
    const count = Object.values(currentFilters).filter(
      (value) => value !== ""
    ).length;
    setActiveFilterCount(count);
  };

  //Endpoint for fetching maintenace workers
  const { data: usersData, error: usersError } = useFetchData(
    selectedFacility ? `/api/work-order/users/${selectedFacility}` : null,
    {},
    [selectedFacility],
    [] // default value for users data
  );
  const users = usersData || [];

  if (usersError) {
    console.error("Error fetching users:", usersError);
  }

  //Endpoint for fetching kiosk locations
  const { data: kiosksData, error: kiosksError } = useFetchData(
    selectedFacility ? `/api/work-order/kiosks/${selectedFacility}` : null,
    {},
    [selectedFacility],
    [] // default value for kiosks data
  );
  const kiosks = kiosksData || [];

  if (kiosksError) {
    console.error("Error fetching kiosks:", kiosksError);
  }

  const handleNewWorkOrder = () => {
    setShowPopup(true);
  };
  const handleShowPrint = () => {
    setShowPrintWorkOrders(true);
  };

  const filterOptions = [
    { name: "title", label: "Title", type: "text" },
    { name: "details", label: "Details", type: "text" },
    { name: "id", label: "ID", type: "text" },
    {
      name: "status",
      label: "Status",
      type: "selector",
      options: [
        { value: "", label: "All Statuses" },
        { value: "incomplete", label: "Incomplete" },
        { value: "unable", label: "Unable to complete" },
        { value: "complete", label: "Complete" },
      ],
    },
    { name: "requestedBy", label: "Requested By", type: "text" },
    {
      name: "requestOrigin",
      label: "Request Origin",
      type: "selector",
      options: [
        { value: "", label: "All Origins" },
        ...kiosks.map((kiosk: Kiosk) => ({
          value: kiosk.location,
          label: kiosk.location,
        })),
        { value: "user-login", label: "User-login" },
      ],
    },
    {
      name: "requestType",
      label: "Request Type",
      type: "selector",
      options: [
        { value: "", label: "All Types" },
        { value: "Electrical", label: "Electrical" },
        { value: "Plumbing", label: "Plumbing" },
        { value: "HVAC", label: "HVAC" },
        { value: "not-specified", label: "Not Specified" },
        { value: "Other", label: "Other" },
      ],
    },
    {
      name: "urgency",
      label: "Urgency",
      type: "selector",
      options: [
        { value: "", label: "All Urgencies" },
        { value: "standard", label: "Standard" },
        { value: "urgent", label: "Urgent" },
      ],
    },
    {
      name: "assignedTo",
      label: "Assigned To",
      type: "selector",
      options: [
        { value: "", label: "All Assignments" },
        { value: "unassigned", label: "Unassigned" },
        ...users.map((user: User) => ({
          value: user._id,
          label: `${user.firstname} ${user.lastname}`,
        })),
      ],
    },
  ];

  return (
    <>
      <PageHeader
        title={"Work Orders"}
        subtitle={"Work orders that have not been closed out yet."}
        buttons={
          <div className="flex flex-col sm:flex-row w-full h-fit justify-evenly sm:gap-4 gap-2">
            <Button
              style="secondary"
              onClick={handleShowPrint}
              icon={"PrintOutlined"}
              children="Work Orders"
              className="sm:w-fit w-full"
            />
            <Button
              style="primary"
              onClick={handleNewWorkOrder}
              icon={"Add"}
              children="Work Order"
              className="w-full"
            />
          </div>
        }
      />
      <div className=" px-4 sm:px-6 sm:overflow-y-auto pb-16 sm:pb-2  thin-scrollbar mb-20">
        <FilterButton
          setShowFilterModal={setShowFilterModal}
        />

        <FilterModal
          show={showFilterModal}
          setShow={setShowFilterModal}
          filterOptions={filterOptions}
          filters={filters}
          applyFilters={applyFilters}
          resetFilters={resetFilters}
        />

        <WorkOrdersTable
          workOrders={workOrders}
          setWorkOrders={setWorkOrders}
          users={users}
          handleView={handleView}
          updateSearchParams={updateSearchParams}
          searchParams={searchParams}
          isLoading={isLoading}
        />
        <Pagination
          itemsToShow={pageSize}
          handlePageSizeChange={handlePageSizeChange}
          currentPage={page}
          setCurrentPage={handlePageChange}
          totalPages={totalPages}
        />
      </div>
      <NewWorkOrder
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        refresh={refresh}
        setRefresh={setRefresh}
      />

      <div>
        <WorkOrderListPrint
          showPrintWorkOrders={showPrintWorkOrders}
          setShowWorkOrderPrint={setShowPrintWorkOrders}
          users={users}
        />
      </div>
    </>
  );
};

export default WorkOrders;
