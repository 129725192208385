import React from "react";
import axios from "axios";
import { CalendarMonthOutlined, Person } from "@mui/icons-material";
import AssignmentSelector from "../general-ui/AssignmentSelector";
import StatusBadge from "../general-ui/StatusBadges";
import { calculateDays, capitalizeString } from "../../utils/FormatFunctions";
import Table from "../general/Table";
import { WorkOrder } from "../../types/WorkOrder";
import { User } from "../../types/User";
import { useAuth } from "../../hooks/useAuth";

interface WorkOrderTableProps {
  workOrders: WorkOrder[];
  setWorkOrders: React.Dispatch<React.SetStateAction<WorkOrder[]>>;
  users: User[];
  handleView: (workOrderId: string) => void;
  searchParams: URLSearchParams;
  updateSearchParams: (params: Record<string, string | number>) => void;
  isLoading: boolean;
}

const WorkOrdersTable = ({
  workOrders,
  setWorkOrders,
  users,
  handleView,
  searchParams,
  updateSearchParams,
  isLoading,
}: WorkOrderTableProps) => {
  const sortColumn = searchParams.get("sortColumn") || "dateReported";
  const sortDirection = searchParams.get("sortOrder") || "1";

  const { currentUser } = useAuth();

  // Function to handle sorting
  const handleSort = (column: string) => {
    if (column === sortColumn) {
      // If the same column is clicked again, toggle the sort direction
      updateSearchParams({ sortOrder: -sortDirection, sortColumn: column });
    } else {
      // If a different column is clicked, set it as the new sort column and default to ascending order
      updateSearchParams({ sortOrder: 1, sortColumn: column });
    }
  };

  const handleAssignment = (workOrder: WorkOrder, selectedUser: string | null) => {
    const workOrderId = workOrder._id;
    const userObject = users.find((user) => user._id === selectedUser);
    
    axios
      .put(
        `/api/work-order/assign/${workOrderId}`,
        {
          assignedTo: selectedUser === "unassign" ? null : selectedUser,
          assignedBy: currentUser._id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        const updatedWorkOrders = workOrders.map((order) => {
          if (order._id === workOrderId) {
            return {
              ...order,
              assignedTo: userObject,
            };
          }
          return order;
        });
        setWorkOrders(updatedWorkOrders);
      })
      .catch((error) => {
        console.error("Error assigning work order:", error);
      });
  };

  const columns = [
    {
      label: "Request Title",
      key: "title",
      sortable: true,
      render: (workOrder: WorkOrder) => (
        <>
          <span className="sm:max-w-96 max-w-56 flex overflow-auto text-sm">
            {capitalizeString(workOrder.title)}
          </span>
          <dl>
            <dt className="sr-only hidden">Details</dt>
            <dd className="mt-1 sm:max-w-72 max-w-56  text-secondary-100  text-xs font-light">
              {workOrder.details}
            </dd>
            <dt className="sr-only hidden">Days since reported</dt>
            <dd className="mt-1 truncate text-secondary-100 text-xs sm:hidden font-light">
              <CalendarMonthOutlined style={{ fontSize: "1rem" }} />{" "}
              {calculateDays(workOrder.dateReported)} days ago
            </dd>

            <dd className="sm:hidden mt-1 flex w-full justify-between text-secondary-100 font-normal text-xs">
              <StatusBadge status={workOrder.status} />

              <span>
                {workOrder.assignedTo ? (
                  <span>
                    <Person
                      style={{ fontSize: "1rem" }}
                      className="h-4 w-4 mr-1"
                    />
                    {capitalizeString(
                      workOrder.assignedTo.firstname.slice(0, 1)
                    )}{" "}
                    {capitalizeString(workOrder.assignedTo.lastname)}
                  </span>
                ) : (
                  <span>
                    <Person
                      style={{ fontSize: "1rem" }}
                      className="h-4 w-4 mr-1"
                    />
                    Unassigned
                  </span>
                )}
              </span>
            </dd>

            <dt className="sr-only hidden">Status and urgency</dt>
            <dd className="mt-1 truncate text-secondary-100  flex sm:flex-col gap-2 flex-row w-full justify-between my-2">
              <span className="md:hidden">
                <StatusBadge status={workOrder.urgency} />
              </span>
              <span className="text-secondary-100 font-normal text-xs">
                ID {workOrder?.shortId}
              </span>
            </dd>
          </dl>
        </>
      ),
      className: "w-full max-w-0 py-4 pr-3 sm:w-auto sm:max-w-none ",
    },
    {
      label: "Date Reported",
      key: "dateReported",
      sortable: true,
      render: (workOrder: WorkOrder) => (
        <>
          <span className=" text-secondary">
            {calculateDays(workOrder.dateReported)} days ago
          </span>
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Location",
      key: "location",
      sortable: true,
      render: (workOrder: WorkOrder) => (
        <>
          <span
            className="hidden sm:inline-flex"
            style={{
              maxWidth: "100px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {capitalizeString(workOrder.location)}
          </span>
        </>
      ),
      className: "hidden xl:table-cell",
    },

    {
      label: "Status",
      key: "status",
      sortable: true,
      render: (workOrder: WorkOrder) => (
        <>
          <StatusBadge status={workOrder.status} />
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Urgency",
      key: "urgency",
      render: (workOrder: WorkOrder) => (
        <>
          <StatusBadge status={workOrder.urgency} />
        </>
      ),
      className: "hidden md:table-cell",
    },
    {
      label: "Assigned to",
      key: "assignedTo",
      sortable: true,
      render: (workOrder: WorkOrder) => (
        <>
          <div onClick={(e) => e.stopPropagation()}>
            <AssignmentSelector
              users={users}
              selectedUser={workOrder.assignedTo ? workOrder.assignedTo : null}
              task={workOrder}
              handleChange={handleAssignment}
            />
          </div>
        </>
      ),
      className: "hidden sm:table-cell",
    },
  ];

  return (
    <Table
      data={workOrders}
      handleSort={handleSort}
      sortColumn={sortColumn}
      sortDirection={sortDirection}
      onRowClick={(workOrder) => handleView(workOrder._id)}
      columns={columns}
      noDataMessage={isLoading ? "Loading..." : "No work orders found"}
    />
  );
};

export default WorkOrdersTable;
