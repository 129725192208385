import React, { useState, useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/ApplicationPages/Home";
import WorkOrders from "./pages/ApplicationPages/WorkOrders";
import Tasks from "./pages/ApplicationPages/RecurringTasks";
import Logs from "./pages/ApplicationPages/Logs";
import Vendors from "./pages/ApplicationPages/Vendors";
import Login from "./pages/Authentication/Login";
import Team from "./pages/ApplicationPages/Team";
import { AuthContext } from "./context/AuthContext";
import TasksWorker from "./pages/ApplicationPages/TasksWorker";
import Registration from "./pages/Authentication/Registration";
import TaskScheduler from "./pages/ApplicationPages/TaskScheduler";
import MyBuilding from "./pages/ApplicationPages/Building";
import SettingsPage from "./pages/ApplicationPages/Settings";
import KioskLogin from "./pages/KioskPages/KioskLogin";
import KioskForm from "./pages/KioskPages/KioskForm";
import NotFound from "./pages/ApplicationPages/NotFound";
import ForgotPassword from "./pages/Authentication/ForgotPassword";
import ApplicationShell from "./updatedcomponents/general-ui/ApplicationShell";
import AdminHome from "./pages/AdminPages/AdminHome";
import Facilities from "./pages/AdminPages/Facilities";
import Users from "./pages/AdminPages/Users";
import ScheduledTasks from "./pages/AdminPages/ScheduledTasks";
import Analytics from "./pages/ApplicationPages/Analytics";
import { jwtDecode } from "jwt-decode";
import SessionExpiredModal from "./updatedcomponents/general-ui/SessionExpiredModal";
import LogInstance from "./pages/ApplicationPages/LogInstance";
import WorkOrderInstance from "./pages/ApplicationPages/WorkOrderInstance";
import LandingPage from "./pages/ApplicationPages/LandingPage";
import { VendorProvider } from "./context/VendorContext";
import UserPage from "./pages/ApplicationPages/User";

function App() {
  const { currentUser } = useContext(AuthContext);
  const role = currentUser?.access;

  const [isSessionExpired, setIsSessionExpired] = useState(false);

  const isAuthenticated = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setIsSessionExpired(true);
      return false;
    }

    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds

      if (decodedToken.exp > currentTime) {
        return true;
      } else {
        setIsSessionExpired(true);
        return false;
      }
    } catch (error) {
      // Handle decoding errors
      console.error("Error decoding token:", error);
      return false;
    }
  };

  const ProtectedRoute = ({ children }) => {
    if (!isAuthenticated()) {
      return <Navigate to="/login" replace />;
    }
    return (
      <>
        {children}
        {!isAuthenticated() && (
          <SessionExpiredModal
            isSessionExpired={isSessionExpired}
            setIsSessionExpired={setIsSessionExpired}
          />
        )}
      </>
    );
  };

  return (
    <div className="font-body">
        <Routes>
          {" "}
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="/landing" element={<LandingPage/>} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/kiosks/login" element={<KioskLogin />} />
          <Route
            path="*"
            element={
              <ProtectedRoute>
                <ApplicationShell />
              </ProtectedRoute>
            }
          >
            <Route
              path="home"
              element={
                role === "worker" ? (
                  <TasksWorker />
                ) : role === "system-admin" ? (
                  <AdminHome />
                ) : (
                  <Home />
                )
              }
            />
            <Route path="work-orders/instance/:id" element={<WorkOrderInstance />} />            
            <Route path="work-orders" element={<WorkOrders />} />
            <Route path="tasks" element={<Tasks />} />
            <Route path="logs" element={<Logs />} />
            <Route path="logs/:id" element={<LogInstance />} />
            <Route path="vendors" element={
              <VendorProvider>
              <Vendors />
              </VendorProvider>
              } />
            <Route path="task-scheduler" element={<TaskScheduler />} />
            <Route path="team" element={<Team />} />
            <Route path="team/:id" element={<UserPage />} />
            <Route path="my-building" element={<MyBuilding />} />
            <Route path="settings" element={<SettingsPage />} />
            <Route path="analytics" element={<Analytics />} />
            {/* Admin routes */}
            <Route path="facilities" element={<Facilities />} />
            <Route path="users" element={<Users />} />
            <Route path="task-catalog" element={<ScheduledTasks />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          {/* Kiosk routes */}
          <Route path="/kiosks/request" element={<KioskForm />} />
          <Route path="/kiosk/:kioskId?" element={<KioskForm />} />
          {/* General routes */}
          <Route path="/register/:token" element={<Registration />} />
        </Routes>
    </div>
  );
}

export default App;
