import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LogsList from "../../updatedcomponents/tables/Logslist";
import WorkOrderLogTable from "../../updatedcomponents/tables/WorkOrderLogTable";
import { FacilityContext } from "../../context/FacilityContext";
import LogPrint from "../../updatedcomponents/modals/LogPrint";
import Pagination from "../../updatedcomponents/table-components/Pagination";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import {
  BuildOutlined,
  EngineeringOutlined,
  HealthAndSafetyOutlined,
  Print,
} from "@mui/icons-material";
import ViewLog from "../../updatedcomponents/modals/ViewLog";
import { classNames } from "../../utils/FormatFunctions";
import { WorkOrder } from "../../types/WorkOrder";
import { RecurringTask } from "../../types/RecurringTask";
import { TaskInstance } from "../../types/TaskInstance";



interface ShowState {
  showLifeSafety: boolean;
  showPrevMaint: boolean;
  showWorkOrders: boolean;
}

const Logs: React.FC = () => {
  const navigate = useNavigate();

  // Show which panel to show
  const [showState, setShowState] = useState<ShowState>({
    showLifeSafety: true,
    showPrevMaint: false,
    showWorkOrders: false,
  });

  const [showPrintState, setShowPrintState] = useState<string | null>(null);

  const handleClosePrint = () => {
    setShowPrintState(null);
  };

  // Function to toggle the display state
  const toggleDisplay = (
    key: keyof ShowState,
    updateFunction: React.Dispatch<React.SetStateAction<ShowState>>
  ) => {
    updateFunction((prevState) => {
      const updatedState: Partial<ShowState> = {};

      // Close all other sections
      Object.keys(prevState).forEach((stateKey) => {
        if (stateKey !== key) {
          updatedState[stateKey as keyof ShowState] = false;
        } else {
          updatedState[stateKey as keyof ShowState] = !prevState[stateKey as keyof ShowState];
        }
      });

      return { ...prevState, ...updatedState } as ShowState;
    });
  };

  const getTrueState = (state: ShowState): string | undefined => {
    const trueState = Object.keys(state).find((key) => state[key as keyof ShowState] === true);
    return trueState;
  };

  const navigation = [
    {
      name: "Life Safety",
      toggleParam: "showLifeSafety" as keyof ShowState,
      current: showState.showLifeSafety,
      icon: <HealthAndSafetyOutlined style={{ fontSize: "1rem" }} />,
    },
    {
      name: "Preventative Maintenance",
      toggleParam: "showPrevMaint" as keyof ShowState,
      current: showState.showPrevMaint,
      icon: <EngineeringOutlined style={{ fontSize: "1rem" }} />,
    },
    {
      name: "Work Orders",
      toggleParam: "showWorkOrders" as keyof ShowState,
      current: showState.showWorkOrders,
      icon: <BuildOutlined style={{ fontSize: "1rem" }} />,
    },
  ];

  const menuNav = (
    <nav className="flex w-fit border rounded-sm my-4 bg-secondary-1100">
      {navigation.map((navItem, index) => (
        <button
          key={index}
          onClick={() => toggleDisplay(navItem.toggleParam, setShowState)}
          className={classNames(
            navItem.current
              ? "bg-primary text-secondary-100 shadow"
              : "text-secondary-100  hover:bg-secondary-1200 hover:shadow-sm",
            "m-1 group flex items-center flex-1 rounded-sm px-2 py-1 text-xs sm:text-sm leading-6 font-normal sm:whitespace-nowrap ",
            index < navigation.length - 1 ? "border-r border-secondary-500" : ""
          )}
        >
          <span className="flex align-middle mr-2">{navItem.icon}</span>{" "}
          {navItem.name}
        </button>
      ))}
    </nav>
  );

  // Global State Management
  const { selectedFacility } = useContext(FacilityContext);

  //Recurring Task State Management
  const [logs, setLogs] = useState<RecurringTask[]>([]);
  // State for displaying a log section
  const [selectedLog, setSelectedLog] = useState<RecurringTask | null>(null);

  const handleViewInstance = async (log: TaskInstance | null) => {
    if (log !== null) {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`/api/log-book/instance/${log._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        navigate(`/logs/${log._id}`, {
          state: { logInstance: response.data },
        });
      } catch (error) {
        console.error("Error fetching log :", error);
      }
    }
  };

  //Work Order Book State Management
  const [workOrders, setWorkOrders] = useState<WorkOrder[]>([]);

  // Pagination
  const [page, setPage] = useState("1");
  const [pageSize, setPageSize] = useState("10");
  const [totalPages, setTotalPages] = useState(1);

  const handlePageSizeChange = ({
    pageSize,
    page,
  }: {
    pageSize: string;
    page: number;
  }) => {
    setPageSize(pageSize);
    setPage(page.toString());
  };

  //Fetches all closed logs for a specified facility
  useEffect(() => {
    const fetchData = async () => {
      let dataType = "";
      if (showState.showLifeSafety) {
        dataType = "life-safety";
      } else if (showState.showPrevMaint) {
        dataType = "prev-maint";
      } else {
        return;
      }

      try {
        if (selectedFacility) {
          const token = localStorage.getItem("token");
          const response = await axios.get(
            `/api/log-book/list/${selectedFacility}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: {
                type: dataType,
              },
            }
          );

          setLogs(response.data);
        }
      } catch (error) {
        console.error("Error loading logs", error);
      }
    };
    const fetchWorkOrders = async () => {
      try {
        if (selectedFacility) {
          const token = localStorage.getItem("token");
          const response = await axios.get(
            `/api/log-book/work-order/list/closed/${selectedFacility}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: {
                page: page,
                pageSize: pageSize,
              },
            }
          );
          setWorkOrders(response.data.workOrders);
          setTotalPages(response.data.totalPages);
        }
      } catch (error) {
        console.error("Error loading logs", error);
      }
    };

    if (showState.showLifeSafety || showState.showPrevMaint) {
      fetchData();
    } else {
      fetchWorkOrders();
    }
  }, [selectedFacility, showState, page, pageSize]);



  const content = (
    <div className="relative bg-secondary-1200 sm:bg-primary">
      <PageHeader
        title={"Log Books"}
        subtitle={"View all closed tasks and work orders for your facility."}
      />
      <div className={`px-4 sm:px-6 lg:px-8`}>
        <>
          {menuNav}
          <div className="flex flex-col sm:flex-row justify-between w-full">
            <div className="flex items-center divide-x divide-secondary-500 gap-4 text-sm">
              <h1 className="font-semibold text-secondary-100">
                {showState.showLifeSafety && "Life Safety"}
                {showState.showPrevMaint && "Preventative Maintenance"}
                {showState.showWorkOrders && "Work Orders"}
              </h1>
              <p className="pl-4 text-secondary-500">List of all logs.</p>
            </div>
            <div className="mt-4 sm:mt-0">
              <button
                type="button"
                className="flex items-center rounded-sm bg-secondary-100 gap-2 px-8 py-2 text-sm text-primary shadow-sm hover:bg-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-500"
                onClick={() => setShowPrintState(getTrueState(showState) || null)}
              >
                <Print fontSize="small" /> <span>Logs</span>
              </button>
            </div>
          </div>
        </>

        {!showState.showWorkOrders && (
          <LogsList data={logs} setSelectedLog={setSelectedLog} />
        )}

        <div>
          {showState.showWorkOrders && (
            <div>
              <div>
                <WorkOrderLogTable workOrders={workOrders} />
                <Pagination
                  itemsToShow={pageSize}
                  currentPage={page}
                  setCurrentPage={(newPage: number) => setPage(newPage.toString())}
                  handlePageSizeChange={handlePageSizeChange}
                  totalPages={totalPages}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <ViewLog
        show={selectedLog !== null}
        handleClose={() => setSelectedLog(null)}
        log={selectedLog}
        handleViewInstance={handleViewInstance}
      />
      <LogPrint
        printState={showPrintState}
        handleClose={handleClosePrint}
        logSections={logs}
      />
    </div>
  );

  return <div>{content}</div>;
};

export default Logs;