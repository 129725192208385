import { useState, useContext } from "react";
import MapList from "../../updatedcomponents/tables/MapList";
import CreateMapButton from "../../updatedcomponents/modals/MapButton";
import FilesList from "../../updatedcomponents/tables/FilesList";
import FileUpload from "../../updatedcomponents/modals/FileUploader";
import { FacilityContext } from "../../context/FacilityContext";
import axios from "axios";
import KioskList from "../../updatedcomponents/tables/KioskList";
import CreateKioskForm from "../../updatedcomponents/forms/CreateKioskForm";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import {
  Add,
  ArrowBack,
  ComputerOutlined,
  MapOutlined,
  PolicyOutlined,
  SourceOutlined,
} from "@mui/icons-material";
import useFetchData from "../../hooks/useFetchData";
const MyBuilding = () => {
  const [showMapCreate, setShowMapCreate] = useState(false);
  const [refreshMap, setRefreshMap] = useState(false);
  const [showFileAdd, setShowFileAdd] = useState(false);
  const [fileType, setFileType] = useState("");
  const [refresh, setRefresh] = useState(false);

  const [refreshKiosk, setRefreshKiosk] = useState(false);
  const [showKioskAdd, setShowKioskAdd] = useState(false);
  const { selectedFacility } = useContext(FacilityContext);

  const [showId, setShowId] = useState(0);

  type FileType = "document" | "policy";

  const handleAddFile = (type: FileType) => {
    setFileType(type);
    setShowFileAdd(true);
  };

  const handleCreateKiosk = () => {
    setShowKioskAdd(true);
  };

  const { data: filesData, error: filesError } = useFetchData(
    selectedFacility ? `/api/building/files/${selectedFacility}` : null,
    {},
    [selectedFacility, refresh],
    { documents: [], policies: [] }
  );

  const { data: kiosksData, error: kiosksError } = useFetchData(
    selectedFacility ? `/api/building/kiosks/${selectedFacility}` : null,
    {},
    [selectedFacility, refreshKiosk],
    { kiosks: [] }
  );

  const documents = filesData?.documents || [];
  const policies = filesData?.policies || [];
  const kiosks = kiosksData?.kiosks || [];

  if (filesError) {
    console.error("Error fetching Document or Policies files:", filesError);
  }

  if (kiosksError) {
    console.error("Error fetching kiosks:", kiosksError);
  }

  interface PageItem {
    id: number;
    icon: any;
    title: string;
    subtitle: string;
    list: any;
    type: string;
    refreshMap?: boolean;
    setRefreshMap?: React.Dispatch<React.SetStateAction<boolean>>;
    clickParam?: any;
    clickFunction: (...args: any) => void;
    kiosks?: object[];
    files?: object[];
    facility?: string;
    refresh?: boolean;
    setRefresh?: React.Dispatch<React.SetStateAction<boolean>>;
  }

  const pageItems: PageItem[] = [
    {
      id: 1,
      icon: MapOutlined,
      title: "Maps",
      subtitle: "View and manage maps for your building",
      list: MapList,
      type: "map",
      refreshMap: refreshMap,
      setRefreshMap: setRefreshMap,
      clickParam: true,
      clickFunction: setShowMapCreate,
    },
    {
      id: 2,
      icon: ComputerOutlined,
      title: "My Kiosks",
      subtitle: "View and manage kiosks for your building",
      list: KioskList,
      type: "kiosk",
      kiosks: kiosks,
      facility: selectedFacility,
      refresh: refreshKiosk,
      setRefresh: setRefreshKiosk,
      clickFunction: handleCreateKiosk,
    },
    {
      id: 3,
      icon: SourceOutlined,
      title: "My Documents",
      subtitle: "View and manage documents for your building",
      list: FilesList,
      type: "documents",
      files: documents,
      facility: selectedFacility,
      refresh: refresh,
      setRefresh: setRefresh,
      clickFunction: handleAddFile,
      clickParam: "document",
    },
    {
      id: 4,
      icon: PolicyOutlined,
      title: "My Policies",
      subtitle: "View and manage policies for your building",
      list: FilesList,
      type: "policies",
      files: policies,
      facility: selectedFacility,
      refresh: refreshKiosk,
      setRefresh: setRefresh,
      clickParam: "policy",
      clickFunction: handleAddFile,
    },
  ];

  const selectedItem = pageItems.find((item) => item.id === showId);

  const content = (
    <div className="flex relative ">
      <ul
        role="list"
        className={`transition-all duration-500 ease-in-out flex gap-4 bg-secondary-1100 sm:bg-primary ${
          showId === 0
            ? "w-full p-4 justify-evenly flex-col sm:flex-row sm:justify-start sm:items-center "
            : "w-1/3 p-4 hidden sm:flex sm:flex-col border-r border-secondary-1000 overflow-y-auto hide-scrollbar-arrows"
        } sm:max-h-[calc(100vh-116px)] overflow-y-auto overflow-x-hidden hide-scrollbar-arrows`}
      >
        {pageItems.map((item) => (
          <li
            key={item.id}
            className={`flex h-full flex-1 border bg-primary ${
              showId === item.id
                ? "border-secondary-100"
                : "border-secondary-1000"
            } ${
              showId !== 0 ? "cursor-pointer" : ""
            } rounded-sm flex flex-col p-4`}
            onClick={() => {
              showId !== 0 && setShowId(item.id);
            }}
          >
            {
              <item.icon
                style={{ fontSize: "2rem" }}
                className={` mb-4  rounded-full p-1.5 ${
                  showId === item.id
                    ? "bg-secondary-100 text-secondary-1000"
                    : "bg-secondary-1000 text-secondary-100"
                }`}
              />
            }

            <p className="text-md font-medium text-secondary-100">
              {item.title}
            </p>
            <p className="text-sm text-secondary-500 pb-4">{item.subtitle}</p>
            <div className="flex h-full flex-col justify-end gap-2">
              <button
                className={`${
                  showId === item.id
                    ? "border border-secondary-100"
                    : "border border-secondary-1000"
                } flex justify-center items-center rounded-sm bg-primary px-3 py-2 text-sm font-semibold text-secondary shadow-sm hover:bg-secondary-1200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 `}
                onClick={() => item.clickFunction(item.clickParam)}
              >
                <Add style={{ fontSize: "1rem" }} /> Add {item.type}
              </button>
              {showId === 0 && (
                <button
                  className="flex justify-center items-center rounded-sm border border-secondary-1000 bg-secondary-1000 px-3 py-2 text-sm font-semibold text-secondary-100 shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                  onClick={() => setShowId(item.id)}
                >
                  View Details
                </button>
              )}
            </div>
          </li>
        ))}
      </ul>
      {showId !== 0 && selectedItem && (
        <div className="m-2 w-full  sm:max-h-[calc(100vh-116px)] overflow-scroll hide-scrollbar-arrows">
          {<selectedItem.list {...selectedItem} />}
        </div>
      )}

        <CreateMapButton
          showMapCreate={showMapCreate}
          setShowMapCreate={setShowMapCreate}
          refreshMap={refreshMap}
          setRefreshMap={setRefreshMap}
          selectedMap={null}
        />
     
      {showFileAdd && (
        <FileUpload
          setShowFileAdd={setShowFileAdd}
          fileType={fileType}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}
      <div>
        {showKioskAdd && (
          <CreateKioskForm
            show={showKioskAdd}
            setShow={setShowKioskAdd}
            refresh={refreshKiosk}
            setRefresh={setRefreshKiosk}
            kiosk={null}
          />
        )}
      </div>
    </div>
  );

  return (
    <div className="relative">
      {showId === 0 ? (
        <PageHeader
          title={<div className="animate-fade-in-right">My Building</div>}
        subtitle={""}

        />
      ) : (
        <PageHeader
          title={
            <button
              className=" text-secondary-100"
              onClick={() => setShowId(0)}
            >
              <div className="flex items-center gap-2">
                <ArrowBack
                  className="text-secondary-300"
                  style={{ fontSize: "1rem" }}
                />
                <span className="animate-fade-in-left">
                  Back to my building
                </span>
              </div>
            </button>
          }
        />
      )}
      {content}
    </div>
  );
};

export default MyBuilding;
